import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout'

const Calculator = () => {
    return (
        <Layout>
            Test
        </Layout>
    )
}

export default Calculator